import api from "./api"

// Function to store the authentication token in localStorage
export function storeToken(token) {
  localStorage.setItem('token', token);
}

// Function to retrieve the authentication token from localStorage
export function getToken() {
  return localStorage.getItem('token');
}

// Function to remove the authentication token from localStorage (logout)
export function removeToken() {
  localStorage.removeItem('token');
}

// Function to check if the user is authenticated based on the presence of the token
export async function isAuthenticated() {
  let response = await api.getReq('/user');
  if(response && response.status == 200){
    return { authenticated: true, user: response.data };
  }
  return { authenticated: false, user: {} };
}
