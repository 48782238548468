import { ref } from 'vue';

export const permissions = ref([]);

export const loadPermissions = () => {
  const storedPermissions = localStorage.getItem('permissions');
  if (storedPermissions) {
    try {
      permissions.value = JSON.parse(storedPermissions);
    } catch (error) {
      console.error('Error parsing permissions from localStorage', error);
    }
  }
};

export const setPermissions = (newPermissions) => {
  permissions.value = newPermissions;
  localStorage.setItem('permissions', JSON.stringify(newPermissions));
};