<template>
<div v-if="!(route.name == 'login') && !(route.name == 'register')">
  <!-- Sidebar -->
  <div class="sidebar" data-background-color="dark">
    <div class="sidebar-logo">
      <!-- Logo Header -->
      <div class="logo-header" data-background-color="dark">
        <a href="index.html" class="logo">
          <img
            src="/assets/img/kaiadmin/logo_light.svg"
            alt="navbar brand"
            class="navbar-brand"
            height="20"
          />
        </a>
        <div class="nav-toggle">
          <button class="btn btn-toggle toggle-sidebar">
            <i class="gg-menu-right"></i>
          </button>
          <button class="btn btn-toggle sidenav-toggler">
            <i class="gg-menu-left"></i>
          </button>
        </div>
        <button class="topbar-toggler more">
          <i class="gg-more-vertical-alt"></i>
        </button>
      </div>
      <!-- End Logo Header -->
    </div>
    <div class="sidebar-wrapper scrollbar scrollbar-inner">
      <div class="sidebar-content">
        <ul class="nav nav-secondary">
          <li class="nav-section">
            <span class="sidebar-mini-icon">
              <i class="fa fa-ellipsis-h"></i>
            </span>
            <p class="text-section">Menu</p>
          </li>

          <li
            class="nav-item"
            v-for="(page, index) in appPages"
            :key="index"
            :class="{
              active: $route.name === page.route || isActiveSubMenu(page),
            }"
          >
            <a
              v-if="page.subMenu"
              data-bs-toggle="collapse"
              :href="'#menu-' + index"
            >
              <i :class="page.icon"></i>
              <p>{{ page.title }}</p>
              <span class="caret"></span>
            </a>

            <div class="collapse" :id="'menu-' + index">
              <ul class="nav nav-collapse">
                <li v-for="subMenu in page.subMenu" :key="subMenu.route">
                  <router-link
                    v-if="subMenu.visible"
                    :to="{ name: subMenu.route, params: subMenu.param }"
                    :class="{ active: $route.name === subMenu.route }"
                  >
                    <span class="sub-item" >{{ subMenu.title }}</span>
                  </router-link>
                </li>
              </ul>
            </div>

            <router-link
              v-if="page.route && !page.subMenu"
              :to="{ name: page.route }"
              :class="{
              active: $route.name === page.route
            }"
            >
              <i :class="page.icon"></i>
              <p>{{ page.title }}</p>
              <span class="badge badge-success">4</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="main-panel">
    <div class="main-header">
      <div class="main-header-logo">
        <!-- Logo Header -->
        <div class="logo-header" data-background-color="dark">
          <a href="index.html" class="logo">
            <img
              src="/assets/img/kaiadmin/logo_light.svg"
              alt="navbar brand"
              class="navbar-brand"
              height="20"
            />
          </a>
          <div class="nav-toggle">
            <button class="btn btn-toggle toggle-sidebar">
              <i class="gg-menu-right"></i>
            </button>
            <button class="btn btn-toggle sidenav-toggler">
              <i class="gg-menu-left"></i>
            </button>
          </div>
          <button class="topbar-toggler more">
            <i class="gg-more-vertical-alt"></i>
          </button>
        </div>
        <!-- End Logo Header -->
      </div>
      <!-- Navbar Header -->
      <nav
        class="navbar navbar-header navbar-header-transparent navbar-expand-lg border-bottom"
      >
        <div class="container-fluid">
          <nav
            class="navbar navbar-header-left navbar-expand-lg navbar-form nav-search p-0 d-none d-lg-flex"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <button type="submit" class="btn btn-search pe-1">
                  <i class="fa fa-search search-icon"></i>
                </button>
              </div>
              <input
                type="text"
                placeholder="Search ..."
                class="form-control"
              />
            </div>
          </nav>

          <ul class="navbar-nav topbar-nav ms-md-auto align-items-center">
            <li
              class="nav-item topbar-icon dropdown hidden-caret d-flex d-lg-none"
            >
              <a
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <i class="fa fa-search"></i>
              </a>
              <ul class="dropdown-menu dropdown-search animated fadeIn">
                <form class="navbar-left navbar-form nav-search">
                  <div class="input-group">
                    <input
                      type="text"
                      placeholder="Search ..."
                      class="form-control"
                    />
                  </div>
                </form>
              </ul>
            </li>
            <!-- <li class="nav-item topbar-icon dropdown hidden-caret">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="messageDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-envelope"></i>
              </a>
              <ul
                class="dropdown-menu messages-notif-box animated fadeIn"
                aria-labelledby="messageDropdown"
              >
                <li>
                  <div
                    class="dropdown-title d-flex justify-content-between align-items-center"
                  >
                    Messages
                    <a href="#" class="small">Mark all as read</a>
                  </div>
                </li>
                <li>
                  <div class="message-notif-scroll scrollbar-outer">
                    <div class="notif-center">
                      <a href="#">
                        <div class="notif-img">
                          <img
                            src="/assets/img/jm_denis.jpg"
                            alt="Img Profile"
                          />
                        </div>
                        <div class="notif-content">
                          <span class="subject">Jimmy Denis</span>
                          <span class="block"> How are you ? </span>
                          <span class="time">5 minutes ago</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <a class="see-all" href="javascript:void(0);"
                    >See all messages<i class="fa fa-angle-right"></i>
                  </a>
                </li>
              </ul>
            </li> -->
            <!-- <li class="nav-item topbar-icon dropdown hidden-caret">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="notifDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-bell"></i>
                <span class="notification">4</span>
              </a>
              <ul
                class="dropdown-menu notif-box animated fadeIn"
                aria-labelledby="notifDropdown"
              >
                <li>
                  <div class="dropdown-title">You have 4 new notification</div>
                </li>
                <li>
                  <div class="notif-scroll scrollbar-outer">
                    <div class="notif-center">
                      <a href="#">
                        <div class="notif-icon notif-primary">
                          <i class="fa fa-user-plus"></i>
                        </div>
                        <div class="notif-content">
                          <span class="block"> New user registered </span>
                          <span class="time">5 minutes ago</span>
                        </div>
                      </a>
                      <a href="#">
                        <div class="notif-icon notif-success">
                          <i class="fa fa-comment"></i>
                        </div>
                        <div class="notif-content">
                          <span class="block"> Rahmad commented on Admin </span>
                          <span class="time">12 minutes ago</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <a class="see-all" href="javascript:void(0);"
                    >See all notifications<i class="fa fa-angle-right"></i>
                  </a>
                </li>
              </ul>
            </li> -->
            <li class="nav-item topbar-icon dropdown hidden-caret">
              <a
                class="nav-link"
                data-bs-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <i class="fas fa-layer-group"></i>
              </a>
              <div class="dropdown-menu quick-actions animated fadeIn">
                <div class="quick-actions-header">
                  <span class="title mb-1">Quick Actions</span>
                  <span class="subtitle op-7">Shortcuts</span>
                </div>
                <div class="quick-actions-scroll scrollbar-outer">
                  <div class="quick-actions-items">
                    <div class="row m-0">
                      <a class="col-6 col-md-4 p-0" href="#">
                        <div class="quick-actions-item">
                          <div class="avatar-item bg-danger rounded-circle">
                            <i class="far fa-calendar-alt"></i>
                          </div>
                          <span class="text">Calendar</span>
                        </div>
                      </a>
                      <a class="col-6 col-md-4 p-0" href="#">
                        <div class="quick-actions-item">
                          <div class="avatar-item bg-warning rounded-circle">
                            <i class="fas fa-map"></i>
                          </div>
                          <span class="text">Maps</span>
                        </div>
                      </a>
                      <a class="col-6 col-md-4 p-0" href="#">
                        <div class="quick-actions-item">
                          <div class="avatar-item bg-info rounded-circle">
                            <i class="fas fa-file-excel"></i>
                          </div>
                          <span class="text">Reports</span>
                        </div>
                      </a>
                      <a class="col-6 col-md-4 p-0" href="#">
                        <div class="quick-actions-item">
                          <div class="avatar-item bg-success rounded-circle">
                            <i class="fas fa-envelope"></i>
                          </div>
                          <span class="text">Emails</span>
                        </div>
                      </a>
                      <a class="col-6 col-md-4 p-0" href="#">
                        <div class="quick-actions-item">
                          <div class="avatar-item bg-primary rounded-circle">
                            <i class="fas fa-file-invoice-dollar"></i>
                          </div>
                          <span class="text">Invoice</span>
                        </div>
                      </a>
                      <a class="col-6 col-md-4 p-0" href="#">
                        <div class="quick-actions-item">
                          <div class="avatar-item bg-secondary rounded-circle">
                            <i class="fas fa-credit-card"></i>
                          </div>
                          <span class="text">Payments</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="nav-item topbar-user dropdown hidden-caret">
              <a
                class="dropdown-toggle profile-pic"
                data-bs-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <div class="avatar-sm">
                  <img
                    src="/assets/img/profile.jpg"
                    alt="..."
                    class="avatar-img rounded-circle"
                  />
                </div>
                <span class="profile-username">
                  <span class="op-7">Hi,</span>
                  <span class="fw-bold">{{ route.meta.user?.name }}</span>
                </span>
              </a>
              <ul class="dropdown-menu dropdown-user animated fadeIn">
                <div class="dropdown-user-scroll scrollbar-outer">
                  <li>
                    <div class="user-box">
                      <div class="avatar-lg">
                        <img
                          src="/assets/img/profile.jpg"
                          alt="image profile"
                          class="avatar-img rounded"
                        />
                      </div>
                      <div class="u-text">
                        <h4>{{ route.meta.user?.name }}</h4>
                        <p class="text-muted">{{ route.meta.user?.email }}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#">Account Setting</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" @click="logOut()">Logout</a>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <!-- End Navbar -->
    </div>

    <div class="container">
    <div class="page-inner">
      <div class="page-header">
        <h4 class="page-title">{{ currentPageTitle }}</h4>
        <ul class="breadcrumbs">
          <li class="nav-home">
            <router-link to="/">
              <i class="icon-home"></i>
            </router-link>
          </li>
          <li class="separator">
            <i class="icon-arrow-right"></i>
          </li>
          <li class="nav-item" v-for="(crumb, index) in breadcrumbs" :key="index">
            <router-link :to="{ name: crumb.route }">{{ crumb.title }}</router-link>
            <span v-if="index < breadcrumbs.length - 1">   
              <li class="separator">
              <i class="icon-arrow-right"></i>
              </li> 
        </span>
          </li>
        </ul>
      </div>
      <router-view />
    </div>
  </div>

    <footer class="footer">
      <div class="container-fluid d-flex justify-content-between">
        <nav class="pull-left">
          <ul class="nav">
            <li class="nav-item">
              <a class="nav-link" href="mailto:hello@dira.lk">
                Email
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="tel:0761033834"> Mobile</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.facebook.com/diralkofficial"> Facebook </a>
            </li>
          </ul>
        </nav>
        <div class="copyright">
          2024, made with <i class="fa fa-heart heart text-danger"></i> by
          <a href="https://dira.lk">dira.lk</a>
        </div>
        <!-- <div>
          Distributed by
          <a target="_blank" href="https://themewagon.com/">ThemeWagon</a>.
        </div> -->
      </div>
    </footer>
  </div>
</div>
<router-view v-else />
</template>

<script setup>
import { computed, ref } from 'vue';
import api from "@/services/api";
import { useRoute } from 'vue-router';
import router from '../src/router';

const route = useRoute();

const appPages = [
  {
    title: "Dashboard",
    route: "home",
    icon: "fas fa-desktop",
  },
  {
    title: "Customer Management",
    icon: "fas fa-user",
    subMenu: [
      {
        title: "Add Customer",
        param: { page: 'create' },
        route: "customer",
        visible: true
      },
      {
        title: "View Customer",
        param: { page: 'view' },
        route: "customer",
        visible: true
      },
      {
        title: "Edit Customer",
        param: { page: 'edit' },
        route: "customer",
        visible: false
      },
    ],
  },
];

const currentPageTitle = computed(() => {
  const currentRouteName = route.name;
  const currentParams = route.params;
  
  for (const page of appPages) {
    if (page.route === currentRouteName) {
      return page.title;
    }
    if (page.subMenu) {
      for (const sub of page.subMenu) {
        if (sub.route === currentRouteName && JSON.stringify(sub.param) === JSON.stringify(currentParams)) {
          return sub.title;
        }
      }
    }
  }
  return '';
});

const breadcrumbs = computed(() => {
  const currentRouteName = route.name;
  const currentParams = route.params;
  let breadcrumbTrail = [];
  
  for (const page of appPages) {
    if (page.route === currentRouteName) {
      breadcrumbTrail.push({ title: page.title, route: page.route });
      return breadcrumbTrail;
    }
    if (page.subMenu) {
      for (const sub of page.subMenu) {
        if (sub.route === currentRouteName && JSON.stringify(sub.param) === JSON.stringify(currentParams)) {
          breadcrumbTrail.push({ title: page.title, route: page.route });
          breadcrumbTrail.push({ title: sub.title, route: sub.route });
          return breadcrumbTrail;
        }
      }
    }
  }
  return breadcrumbTrail;
});

function isActiveSubMenu(page) {
  return page.subMenu && page.subMenu.some(subMenu => route.name === subMenu.route);
};

async function logOut(){
  await api.postReq('/logout', null);
  router.push('/login');
};
</script>