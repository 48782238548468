<template>
  <div
    class="row"
    v-if="(Array.isArray(tableData) && tableData.length) || search != ''"
  >
    <div class="col-md-1">
      <select @change="load()" class="form-control mb-2" v-model="per_page">
        <option value="10">10</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <div class="col-md-8"></div>
    <div class="col-md-3">
      <div class="input-icon">
        <input
          type="text"
          class="form-control"
          placeholder="Search for..."
          v-model="search"
          @input="debouncedLoad()"
        />
        <span class="input-icon-addon">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>
  <div v-if="!isLoading && Array.isArray(tableData) && tableData.length">
    <table class="table table-hover table-striped mt-3">
      <thead class="">
        <tr>
          <th>#</th>
          <th v-for="(header, key) in tableAttributes">{{ header }}</th>
          <th style="width: 15%">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in tableData">
          <td>{{ index + 1 + (meta.current_page - 1) * meta.per_page }}</td>
          <td v-for="(header, key) in tableAttributes">
            {{ getValue(data, key) }}
          </td>
          <td>
            <a href="javascript:void(0)" @click="show(data)"
              ><i class="far fa-eye link-dark" title="Show"></i
            ></a>
            <a href="javascript:void(0)"
              ><i class="far fa-edit link-success" @click="edit(data)" title="Edit"></i
            ></a>
            <a href="javascript:void(0)" @click="destroy(data.id)"
              >
              <i
                class="fas fa-trash-alt link-danger"
                data-toggle="tooltip"
                data-placement="top"
                title="Delete"
              ></i
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-end">
        <li class="page-item" v-for="page in meta.links">
          <a
            :class="{
              'page-link': true,
              active: page.active,
              disabled: page.url == null,
            }"
            @click="paginate(page.url)"
            href="javascript:void(0)"
            v-html="page.label"
          ></a>
        </li>
      </ul>
    </nav>
  </div>
  <center>
    <div class="spinner-border" role="status" v-if="isLoading">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-if="!isLoading && !(Array.isArray(tableData) && tableData.length)">
      <span class="text-dark">No data available</span>
    </div>
  </center>
</template>

<script setup>
import { onMounted, ref } from "vue";
import api from "@/services/api";
import { debounce } from "lodash";

const isLoading = ref(0);
const tableData = ref([]);
const meta = ref([]);
const per_page = ref(10);
const search = ref("");
const page = ref(1);

const props = defineProps({
  pageSetup: {
    type: Object,
    default: "",
  },

  tableAttributes: {
    type: Object,
    default: "",
  },

  route: {
    type: String,
    default: "",
  },
});

// Define the emit function
const emit = defineEmits(["set-page", "edit", "show"]);

onMounted(async () => {
  load(page.value);
});

async function load(page) {
  isLoading.value = 1;
  try {
    const route = props.route;
    const response = await api.getReq(
      `${route}?page=${page}&per_page=${per_page.value}&search=${search.value}`
    );
    let data = response.data;
    tableData.value = data.data;
    meta.value = data.meta;
    emit("total-count", meta.value.total);
  } catch (error) {
    api.toast(
      "error",
      "An unexpected error occurred",
      "Please try again later!"
    );
  } finally {
    isLoading.value = 0;
  }
}

function paginate(pageUrl) {
  const url = new URL(pageUrl);
  const searchParams = new URLSearchParams(url.search);
  page.value = searchParams.get("page");
  load(page.value);
}

function getValue(obj, key) {
  const keys = key.split(".");
  return keys.reduce(
    (value, currentKey) => (value ? value[currentKey] : ""),
    obj
  );
}

function setPage(page) {
  emit("set-page", page);
}

function edit(data) {
  emit("edit", data);
}

function show(data) {
  emit("show", data);
}

const debouncedLoad = debounce(load, 300); // 300ms delay

function destroy(id) {
  Swal.fire({
    title: "Are you sure you want to delete?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#f06548",
    cancelButtonColor: "#4b38b3",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      api.destroy(props.route, id);
      load(page.value);
    }
  });
}
</script>

<style scoped>
.table {
  border: 1px solid #e6e6e6;
}
</style>
