<template>
  <body>
    <div class="container">
      <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div class="card border-0 shadow rounded-3 my-5">
            <div class="card-body p-4 p-sm-5">
              <h5 class="card-title text-center mb-5 fw-light fs-5">Sign In</h5>
              <form>
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    id="floatingInput"
                    placeholder="name@example.com"
                    :class="{
                    'form-control': true,
                    'is-invalid': formErrors.email,
                  }"
                    v-model="formData.email"
                  />
                  <label for="floatingInput">Email address</label>
                  <div class="invalid-feedback">
                  {{ formErrors.email ? formErrors.email[0] : "" }}
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="password"
                    id="floatingPassword"
                    placeholder="Password"
                    v-model="formData.password"
                    :class="{
                    'form-control': true,
                    'is-invalid': formErrors.password,
                  }"
                  />
                  <div class="invalid-feedback">
                  {{ formErrors.password ? formErrors.password[0] : "" }}
                  </div>
                  <label for="floatingPassword">Password</label>
                </div>

                <div class="d-grid mb-2">
                  <button
                  @click="Login()"
                    class="btn btn-primary btn-login text-uppercase fw-bold"
                    type="button"
                  >
                    Sign in
                  </button>
                </div>
                <div class="d-grid">
                  <router-link
                  :to="{ name: 'register' }"
                    class="btn btn-primary btn-login text-uppercase fw-bold form-control"
                    type="submit"
                  >
                    Register
                  </router-link>
                </div>
                
                <!-- <hr class="my-4" />
                <div class="d-grid mb-2">
                  <button
                    class="btn btn-google btn-login text-uppercase fw-bold"
                    type="submit"
                  >
                    <i class="fab fa-google me-2"></i> Sign in with Google
                  </button>
                </div>
                <div class="d-grid">
                  <button
                    class="btn btn-facebook btn-login text-uppercase fw-bold"
                    type="submit"
                  >
                    <i class="fab fa-facebook-f me-2"></i> Sign in with Facebook
                  </button>
                </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>
<script setup>
import { ref } from 'vue';
import api from "@/services/api";
import router from '@/router';
import { setPermissions } from '@/services/state';

const isLoading = ref(0);
const formErrors = ref({});
const formData = ref({
  email: '',
  password: '',
});

async function Login() {
  try {
    isLoading.value = 1;
    let response = await api.login('/login', formData.value);
    const { data, errors, status } = response;
    if(status == 200){
      // localStorage.setItem('token', data.token);
      router.push('/');
    }else if(status == 422){
      formErrors.value = errors;
    }
  } catch (error) {
    api.toast('error', error.message);
  } finally {
    isLoading.value = 0;
  }
}

async function getUser() {
  try {
    isLoading.value = 1;
    
    res.value = await api.getReq('/user');
 
  } catch (error) {
    api.toast('error', 'Failed to fetch');
  } finally {
    isLoading.value = 0;
  }
}

</script>