import axios from "axios";
import Swal from 'sweetalert2';

// const API_URL = 'http://127.0.0.1:8000/api';
const API_URL = '/api';

const api = {
  getHeader() {
    // const token = localStorage.getItem('token');
    // return {
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer ${token}` 
    // };
  },

  async getReq(endPint) {
    try {
      const headers = this.getHeader();
      const response = await axios.get(`${API_URL}${endPint}`, { headers });
      return {
        data: response.data,
        errors: [],
        status: response.status
      };
    } catch (error) {
      this.handleError(error);
    }
  },

  async postReq(endPint, params) {
    try {
      const headers = this.getHeader();
      const response = await axios.post(`${API_URL}${endPint}`, params, { headers });
      return {
        data: response.data,
        errors: [],
        status: response.status
      };
    } catch (error) {
      return this.handleError(error);
    }
  },

  async putReq(endPint, params) {
    try {
      const headers = this.getHeader();
      const response = await axios.put(`${API_URL}${endPint}`, params, { headers });
      this.toast('success', response.data.message, '');
      return {
        data: response.data,
        errors: [],
        status: response.status
      };
    } catch (error) {
      return this.handleError(error);
    }
  },

  async destroy(endPint, id) {
    try {
      const headers = this.getHeader();
      const response = await axios.delete(`${API_URL}${endPint}${id}`, { headers });
      this.toast('success', response.data.message, '');
    } catch (error) {
      this.handleError(error, "Can't Delete This Record");
    }
  },

  async login(endPint, params) {
    try {
      const response = await axios.post(`${API_URL}${endPint}`, params);
      return {
        data: response.data,
        errors: [],
        status: response.status
      };
    } catch (error) {
      return {
        data: [],
        errors: error.response.data.errors,
        status: error.response.status
      }
    }
  },

  toast(type, title, text = 'Please try again later!') {
    Swal.fire({
      icon: type,
      title: title,
      text: text,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  },

  handleError(error, defaultMessage = 'Failed to fetch') {
    if (error.response) {
      if (error.response.status === 422) {
        return {
          data: [],
          errors: error.response.data.errors,
          status: 422
        };
      }
      if (error.response.status === 403) {
        this.toast('warning', error.response.data.error, '');
        return {
          data: [],
          errors: error.response.data.errors,
          status: 423
        };
      }
      if (error.response.status === 401) {
        return {
          data: [],
          errors: error.response.data.errors,
          status: 401
        };
      }
      this.toast('error', error.response.data.message || defaultMessage);
      return {
        data: [],
        errors: [{ message: error.response.data.message || defaultMessage }],
        status: error.response.status
      };
    } else if (error.request) {
      this.toast('error', 'Network error: Please check your connection.');
      return {
        data: [],
        errors: [{ message: 'Network error: Please check your connection.' }],
        status: null
      };
    } else {
      this.toast('error', error.message);
      return {
        data: [],
        errors: [{ message: error.message }],
        status: null
      };
    }
  }
};

export default api;