import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/auth/LoginPage.vue'
import RegisterView from '../views/auth/RegisterPage.vue'
import CustomerView from '../views/customer/CustomerView.vue'
import { isAuthenticated } from '@/services/authService';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/customer/:page',
    name: 'customer',
    component: CustomerView,
    meta: { requiresAuth: true }
  },
  {
    path: '/project/:page',
    name: 'project',
    component: CustomerView,
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import('../views/AboutView.vue')
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Global navigation guard to check authentication
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const { authenticated, user } = await isAuthenticated();
    if (authenticated) {
      to.meta.user = user;
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router
