<template>
  <div class="home">
    <div class="card">
      <div class="card-header">
        <div class="card-title text-uppercase">{{ $route.params.page }}</div>
      </div>
      <div class="card-body">
        <form
          @submit.prevent="handleSubmit()"
          v-if="$route.params.page != 'view'"
        >
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  :class="{
                    'form-control': true,
                    'is-invalid': formErrors.name,
                  }"
                  v-model="formData.name"
                />
                <div class="invalid-feedback">
                  {{ formErrors.name ? formErrors.name[0] : "" }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Email</label>
                <input
                  type="email"
                  :class="{
                    'form-control': true,
                    'is-invalid': formErrors.email,
                  }"
                  v-model="formData.email"
                />
                <div class="invalid-feedback">
                  {{ formErrors.email ? formErrors.email[0] : "" }}
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Contact</label>
                <input
                  type="number"
                  :class="{
                    'form-control': true,
                    'is-invalid': formErrors.contact,
                  }"
                  v-model="formData.contact"
                />
                <div class="invalid-feedback">
                  {{ formErrors.contact ? formErrors.contact[0] : "" }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <button
                  type="submit"
                  style="float: right"
                  class="btn btn-success"
                  :disabled="isLoading"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
        <TableComponent
          v-if="$route.params.page == 'view'"
          :route="route"
          :tableAttributes="tableAttributes"
          :pageSetup="pageSetup"
          :primaryKey="primaryKey"
          @set-page="setPage"
          @edit="edit"
          @show="show"
          @total-count="totalCount"
        >
        </TableComponent>
      </div>
    </div>
  </div>

  <ShowModal :combinedAttributes="combinedAttributes" :formData="formData"></ShowModal>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import api from "@/services/api";
import TableComponent from "@/components/TableComponent";
import { useRouter } from "vue-router";
import ShowModal from "@/components/ShowModel";
const router = useRouter();

const formData = ref({});
const formErrors = ref({});
const isLoading = ref(false);
const primaryKey = "id";
const route = "/customer";

const tableAttributes = ref({
  name: "Name",
  email: "Email",
  contact: "Contact",
});

const extraAttributes = ref({
  // description: "Description",
});

const combinedAttributes = ref({
  ...tableAttributes.value,
  ...extraAttributes.value
});

onMounted(async () => {
  if (
    router.currentRoute.value.params.page === "edit" &&
    Object.keys(formData.value).length === 0
  ) {
    showViewPage();
  }
});

watch(() => router.currentRoute.value.params.page, (newPage, oldPage) => {
  if (newPage == 'create') {
    formData.value = {};
  }
});

const handleSubmit = () => {
  if (formData.value.id) {
    update(formData.value.id);
  } else {
    create();
  }
};

async function create() {
  isLoading.value = true;
  formErrors.value = {};
  let response = await api.postReq(route, formData.value);
  const { data, errors, status } = response;
  if (status == 200) {
    api.toast("success", "Customer created successfully", "");
    formData.value = {};
  } else if (status == 422) {
    formErrors.value = errors;
  }
  isLoading.value = false;
}

async function update(id) {
  isLoading.value = true;
  formErrors.value = {};
  let response = await api.putReq(`${route}/${id}`, formData.value);
  const { data, errors, status } = response;
  if (status == 200) {
    api.toast("success", "Customer updated successfully", "");
    showViewPage();
  } else if (status == 422) {
    formErrors.value = errors;
  }
  isLoading.value = false;
}

function edit(data) {
  formData.value = data;
  router.push({
    name: "customer",
    params: {
      page: "edit", // Required page parameter
    },
  });
}

function showViewPage() {
  formData.value = {};
  router.push({
    name: "customer",
    params: {
      page: "view",
    },
  });
}

function show(data) {
  formData.value = data;
  $("#show").modal("show");
}
</script>
