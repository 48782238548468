<template>
  <div class="modal fade" id="show" tabindex="-1" aria-modal="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">SHOW</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6" v-for="(label, key) in combinedAttributes">
            <div class="form-group mb-2">
              <label>{{ label }}</label>
              <input type="text" :value="getValue(formData, key)" class="form-control" readonly />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
const props = defineProps({
combinedAttributes: {
  type: Object,
  default: "",
},

formData: {
  type: Object,
  default: "",
},
});

function getValue(obj, key) {
const keys = key.split(".");
return keys.reduce(
  (value, currentKey) => (value ? value[currentKey] : ""),
  obj
);
}
</script>